import React from 'react';
import { Helmet } from 'react-helmet';

function MisOnUni() {
  return (
    <>
      <Helmet>
        <title>Mis on Uni? - Unenägude Seletaja</title>
        <meta name="description" content="Õpi tundma une teadust ja miks uni on meile oluline. Avastage une tsüklid ja kuidas uni mõjutab meie tervist ja heaolu." />
        <meta name="keywords" content="mis on uni, une teadus, une tsüklid, une tähtsus, tervis ja uni" />
        <meta name="author" content="Teie Nimi" />
      </Helmet>
      <main style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Mis on Uni?</h1>
        <article>
          <section>
            <h2>Une Teadus ja Bioloogia</h2>
            <p>Uni on keeruline bioloogiline protsess, mis hõlmab aju ja keha taastamist ja regenereerimist. Uni on oluline meie füüsilise ja vaimse tervise jaoks.</p>
            <ul>
              <li>Unetsüklid ja nende etapid</li>
              <li>REM ja mitte-REM uni</li>
              <li>Une mõju ajule ja kehale</li>
            </ul>
          </section>

          <section>
            <h2>Une Tsüklid ja Etapid</h2>
            <p>Uni jaguneb erinevateks tsükliteks ja etappideks, millest igaüks mängib olulist rolli meie üldises tervises ja heaolus. Peamised etapid on:</p>
            <ol>
              <li><strong>Etapp 1:</strong> Kerge uni, üleminek ärkvelolekult unne.</li>
              <li><strong>Etapp 2:</strong> Kerge uni, kus keha hakkab lõdvestuma.</li>
              <li><strong>Etapp 3:</strong> Sügav uni, mis on oluline füüsiliseks taastumiseks.</li>
              <li><strong>REM uni:</strong> Unenäod tekivad, aju on aktiivne ja keha on lõdvestunud.</li>
            </ol>
          </section>

          <section>
            <h2>Une Tähtsus ja Kasulikkus</h2>
            <p>Uni on hädavajalik meie tervise ja heaolu jaoks. Siin on mõned põhjused, miks uni on nii oluline:</p>
            <ul>
              <li><strong>Füüsiline taastumine:</strong> Keha parandab ja taastab kudesid ja lihaseid une ajal.</li>
              <li><strong>Vaimne tervis:</strong> Uni aitab säilitada kognitiivseid funktsioone ja emotsionaalset tasakaalu.</li>
              <li><strong>Immuunsüsteem:</strong> Uni tugevdab immuunsüsteemi, aidates võidelda haigustega.</li>
            </ul>
          </section>

          <section>
            <h2>Une Parandamise Soovitused</h2>
            <p>Kui teil on probleeme une kvaliteediga, proovige järgmisi soovitusi:</p>
            <ul>
              <li><strong>Järgige regulaarset uneplaani:</strong> Minge magama ja ärkake iga päev samal ajal.</li>
              <li><strong>Loo rahulik magamiskeskkond:</strong> Veenduge, et teie magamistuba on pime, vaikne ja jahe.</li>
              <li><strong>Vältige kofeiini ja elektroonikat enne magamaminekut:</strong> Need võivad häirida teie uinumist.</li>
            </ul>
          </section>
        </article>
      </main>
    </>
  );
}

export default MisOnUni;