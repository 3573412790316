// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function Footer() {
  return (
    <div className="footer">
        <nav className="navbar">
          <div className="navbar-left">
            <Link to="/privaatsus-poliitika">Privaatsuspoliitika</Link>
          </div>
          <div className="navbar-right">
            <Link to="/kontakt">Kontakt</Link>
          </div>
    </nav>
    </div>
  );
}

export default Footer;