import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = useAuthContext(); 

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/login', {email, password}, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true
      });

      localStorage.setItem('user', JSON.stringify(response.data)); 
      dispatch ({ type: 'LOGIN', payload: response.data }); 
      
      ReactGA.event({
        category: 'User',
        action: 'Login',
        label: 'Email'
      });
      
      setLoading(false);
      navigate('/', { state: { fromSignup: true } });

      } catch (e) {
        ReactGA.event({
          category: 'User',
          action: 'Login failure',
          label: 'Email'
        });
          setLoading(false);
          setError(e.response.data.error);
      }
  } 

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login/google`, {
        token: credentialResponse.credential,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      });

      ReactGA.event({
        category: 'User',
        action: 'Login',
        label: 'Google'
      });

      localStorage.setItem('user', JSON.stringify(result.data));
      dispatch({ type: 'LOGIN', payload: result.data });
      navigate('/', { state: { fromSignup: true } });

    } catch (error) {
      ReactGA.event({
        category: 'User',
        action: 'Login failure',
        label: 'Google'
      });

      setError('Failed to login with Google.');
    }
  };

  const handleGoogleFailure = (error) => {
    console.error('Google Sign In was unsuccessful. Try again later', error);
    setError('Google Sign In was unsuccessful. Try again later');
  };

  const handleFacebookResponse = async (response) => {
    if (response.accessToken) {
      try {
        const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login/facebook`, {
          accessToken: response.accessToken,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });

        ReactGA.event({
          category: 'User',
          action: 'Login',
          label: 'Facebook'
        });

        localStorage.setItem('user', JSON.stringify(result.data));
        dispatch({ type: 'LOGIN', payload: result.data });
        navigate('/', { state: { fromSignup: true } });
      } catch (error) {
        ReactGA.event({
          category: 'User',
          action: 'Login failure',
          label: 'Facebook'
        });
        setError('Failed to login with Facebook.');
      }
    } else {
      ReactGA.event({
        category: 'User',
        action: 'Login failure',
        label: 'Facebook'
      });
      setError('Facebook Sign In was unsuccessful. Please try again.');
    }
  };


  return (
    <div className="login-container">
      <h2>Logi sisse</h2>
  
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Parool"
      />
      <button
        disabled={loading || !email || !password}
        onClick={handleLogin}
      >
        Logi sisse
      </button>
      {error && <div className="error">{error}</div>}
  
      <h3>Või kasuta neid teenuseid:</h3>
      
      <div className="social-login">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
          text="Loo konto Google'iga"  
        />
      </div>
      
      <div className="social-login">
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          autoLoad={false}
          fields="email"
          callback={handleFacebookResponse}
          textButton="Sign In with Facebook"
          locale="et_EE"  
        />
      </div>
      
      <div>
        Pole veel kontot? <Link to="/signup">Loo siin</Link>
      </div>
    </div>
  );
}

export default Login;
