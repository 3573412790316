import React from 'react';
import { Helmet } from 'react-helmet';

function PrivaatsusPoliitika() {
  return (
    <>
      <Helmet>
        <title>Privaatsuspoliitika - Unenägude Seletaja</title>
        <meta name="description" content="Lugege meie privaatsuspoliitika kohta, et saada teada, kuidas me kogume ja kasutame andmeid teie unenägude tõlgendamisel." />
        <meta name="keywords" content="privaatsuspoliitika, andmekaitse, isikuandmed, privaatsus" />
        <meta name="author" content="Unenägude seletaja" />
        <link rel="canonical" href="https://www.unes.ee/privaatsus-poliitika" />
      </Helmet>
      <main style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
        <h1>Privaatsuspoliitika</h1>
        <article>
          <section>
            <p>Teie privaatsus on meile väga oluline. Käesolev privaatsuspoliitika selgitab, milliseid andmeid me kogume, kuidas me neid kasutame ja kuidas me neid kaitseme.</p>
          </section>
          <section>
            <h2>Andmete kogumine</h2>
            <p>Me kogume andmeid järgmistel viisidel:</p>
            <ul>
              <li>Unenäod: Kogume unenägusid, et neid anonüümselt tõlgendada.</li>
              <li>IP-aadressid: Kogume IP-aadresse, et filtreerida spämmereid ja kurjategijaid. Kahtlase tegevuse korral esitame andmed politseile.</li>
            </ul>
          </section>
          <section>
            <h2>Kogutavad andmed</h2>
            <ul>
              <li><strong>Unenäod:</strong> Teie poolt esitatud unenäod, mis salvestatakse ja töödeldakse anonüümselt.</li>
              <li><strong>IP-aadressid:</strong> IP-aadressid, mida kasutatakse turvalisuse tagamiseks ja pahatahtlike tegevuste ennetamiseks.</li>
            </ul>
          </section>
          <section>
            <h2>Andmete kasutamine</h2>
            <p>Kasutame kogutud andmeid järgmistel eesmärkidel:</p>
            <ul>
              <li>Unenägude anonüümne tõlgendamine ja analüüs.</li>
              <li>Turvalisuse tagamine ja spämmi ennetamine.</li>
              <li>Pahatahtlike tegevuste avastamine ja ennetamine; vajadusel andmete esitamine politseile.</li>
            </ul>
          </section>
          <section>
            <h2>Andmete jagamine</h2>
            <p>Me ei jaga teie andmeid kolmandate osapooltega, välja arvatud juhul, kui see on vajalik turvalisuse tagamiseks või seadusest tulenevalt.</p>
          </section>
          <section>
            <h2>Andmete kaitse</h2>
            <p>Kasutame asjakohaseid turvameetmeid, et kaitsta teie andmeid volitamata juurdepääsu, muutmise, avalikustamise või hävitamise eest.</p>
          </section>
          <section>
            <h2>Teie õigused</h2>
            <p>Teil on õigus pääseda ligi oma andmetele, neid parandada või kustutada. Samuti on teil õigus oma nõusolek tagasi võtta ja esitada kaebus andmekaitseasutusele.</p>
          </section>
          <section>
            <h2>Privaatsuspoliitika muudatused</h2>
            <p>Võime seda privaatsuspoliitikat aeg-ajalt muuta. Kõik muudatused avaldatakse sellel lehel. Soovitame teil seda lehte regulaarselt külastada, et olla teadlik meie privaatsuspoliitika uuendustest.</p>
          </section>
          <section>
            <h2>Kontakt</h2>
            <p>Küsimuste korral privaatsuspoliitika kohta võtke meiega ühendust: info[ät].unes.ee</p>
          </section>
        </article>
      </main>
    </>
  );
}

export default PrivaatsusPoliitika;
