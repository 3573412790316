import React from 'react';
import { Helmet } from 'react-helmet';

function Meist() {
  return (
    <>
      <Helmet>
        <title>Meist - Unenägude Seletaja</title>
        <meta name="description" content="Tutvuge meie ettevõtte ja meeskonnaga, kes on pühendunud teie unenägude tõlgendamisele. Uurige meie lugu ja missiooni." />
        <meta name="keywords" content="meist, meie lugu, unenäoseletaja meeskond" />
        <meta name="author" content="Unenägude seletaja" />
        <link rel="canonical" href="https://www.unes.ee/meist" />
      </Helmet>
      <main style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
        <h1>Meist</h1>
        <article>
          <section>
            <p>Tere tulemast meie veebisaidile! Meie ettevõte on pühendunud pakkuma parimat unenäoseletusteenust ja tooteid oma klientidele. Oleme meeskond, kes usub kvaliteeti ja klientide rahulolu.</p>
          </section>
          <section>
            <h2>Meie lugu</h2>
            <p>Meie ettevõte asutati eesmärgiga tuua turule uuenduslikke ja usaldusväärseid lahendusi unenäoseletuse vallas. Alustasime väikese meeskonnaga, kuid oleme aastate jooksul kasvanud ja laienenud, säilitades siiski oma põhiväärtused ja pühendumuse kvaliteedile.</p>
          </section>
          <section>
            <h2>Meie missioon</h2>
            <p>Meie missioon on pakkuda klientidele tipptasemel unenäoseletusteenuseid ja -tooteid, mis vastavad nende vajadustele ja ületavad nende ootusi. Püüame pidevalt täiustada oma pakkumisi ja olla usaldusväärne partner oma klientidele.</p>
          </section>
          <section>
            <h2>Meie väärtused</h2>
            <ul>
              <li><strong>Kliendikesksus:</strong> Meie kliendid on meie prioriteet ja me töötame selle nimel, et pakkuda neile parimat võimalikku kogemust.</li>
              <li><strong>Innovatsioon:</strong> Me otsime pidevalt uusi ja paremaid viise oma toodete ja teenuste täiustamiseks.</li>
              <li><strong>Ausus:</strong> Me tegutseme ausalt ja läbipaistvalt kõigis oma ettevõtmistes.</li>
              <li><strong>Kvaliteet:</strong> Meie eesmärk on pakkuda ainult kõrgeima kvaliteediga tooteid ja teenuseid.</li>
            </ul>
          </section>
          <section>
            <h2>Meeskond</h2>
            <p>Meie meeskond koosneb kogenud ja pühendunud unenäoseletuse spetsialistidest, kes jagavad ühist visiooni ja pühendumust tipptasemele. Iga meie liige toob meeskonda unikaalseid oskusi ja teadmisi, mis aitavad meil saavutada meie eesmärke.</p>
          </section>
          <section>
            <h2>Kontakt</h2>
            <p>Kui teil on küsimusi või soovite meiega ühendust võtta, võtke julgelt ühendust:</p>
            <ul>
              <li><strong>E-post:</strong> info[ät].unes.ee</li>
            </ul>
          </section>
        </article>
      </main>
    </>
  );
}

export default Meist;
