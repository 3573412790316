// Profile.js
import React from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { useLogout } from '../hooks/useLogout';

function Profile() {
  const { user } = useAuthContext();
  const { logout , error, loading } = useLogout();
  
  const handleLogout = () => {
    logout();
  }

  if (!user) {
    return <div>Logi sisse, et profiili näha.</div>;
  }
 
  return (
    <div>
      <h2>Profiil</h2>
      <p>Kasutaja: {user.nickname}</p>
      <button disabled={loading} onClick={handleLogout}>Logi välja</button>
      {error && <div className="error">{error}</div>}
      {/* Add more profile details or editing functionality here */}
    </div>
  );
}

export default Profile;