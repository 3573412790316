import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { useLogout } from '../hooks/useLogout';
import { useNavigate, useLocation} from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';

function UnenagudeSeletaja() {
  
  const { user, dream, dispatch } = useAuthContext();

  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [remainingChars, setRemainingChars] = useState(dream.length || 0);
  const [error, setError] = useState(null);
  const {logout} = useLogout();
  const navigate = useNavigate();
  const location = useLocation();


  const messages = [
    'Edasi lugemiseks loo konto ja saa tasuta 1€ krediiti',
    'Edasi lugemiseks loo konto ja saa tastuta 10 krediiti',
    'Edasi lugemiseks loo konto',
    'Edasi lugemiseks kliki siia',
    'Täieliku seletuse lugemiseks maksa 1€',
  ];

  // Initialize selectedMessage using useState with a function
  const [selectedMessage] = useState(() => {
  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex];
  });
  
  const handleSubmit = async () => {

    setLoading(true);
    setResponse('');
    setError(null);

    ReactGA.event({
      category: 'User',
      action: 'Submit Dream'
    });

    if (user) {
        try {
          const res = await axios.post(process.env.REACT_APP_BACKEND_URL + '/dream/interpret', { text: dream}, {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true
          });
          
          setResponse(res.data);

        } catch (error) {
          if (error.response && error.response.status === 401) {
            setError('Logitud välja.')
            logout();
          }
          
        } finally {
          setLoading(false);
        }
    } else {
      
    try {
        const res = await axios.post(process.env.REACT_APP_BACKEND_URL + '/dream/shortinterpret', { text: dream }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      });
      
      setResponse(res.data);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Logitud välja.')
        logout();
      }
    
    } finally {
      setLoading(false);
    }
  }

  };

  const handleSignUpIntent = async () => {
    ReactGA.event({
      category: 'User',
      action: 'Intent to Create Account',
      label: selectedMessage
    });
    dispatch({ type: 'SET_DREAM', payload: dream }); 
    navigate('/signup');
  };


  const handleChange = (e) => {

    const value = e.target.value;
    if (value.length <= 400) {
      dispatch({ type: 'SET_DREAM', payload: value });
      setRemainingChars(value.length);
    }
  };

   // if we are coming from signup or login, submit the dream automatically
   useEffect(() => {
    if (location.state?.fromSignup && user) {
      if (dream) {
        handleSubmit();
      }
      navigate(location.pathname, { replace: true, state: {} }); 
    }
  }, [location.state, user, dream, handleSubmit, location.pathname, navigate]);


  return (
    <>
      <Helmet>
        <title>Unenägude Seletaja - Kirjelda oma und</title>
        <meta name="description" content="Sisesta oma unenägu ja saa teada, mida see tähendab. Meie unenäo seletaja pakub täpseid ja intuitiivseid tõlgendusi." />
        <meta name="keywords" content="unenägude seletaja, unenägude seletamine, nägin unes, unenäo tõlgendamine, unenägude tähendus, unenäod" />
        <meta name="author" content="Unenägude seletaja" />
        <link rel="canonical" href="https://www.unes.ee" />
      </Helmet>

        <div className="cat">
          <img src="/img/dreamcatcher_mobile.png" className="dreamcatcher-image" alt="Dreamcatcher" />
          <h2>Kirjelda Oma Unenägu</h2>
          
          <textarea 
          placeholder="nägin unes..."
            value={dream} 
            onChange={handleChange} 
            disabled={loading}
            rows="2"
          />
        <div>{remainingChars}/400 tähte jäänud</div>
          {loading ? (
            <>
              <span>Seletan </span>
              <img src="/loading.gif" alt="Loading..." />
            </>
          ) : (
            <button disabled={loading || dream.length === 0} onClick={handleSubmit}> Seleta </button>
          )}

          {error && <div className="error">{error}</div>}
      </div>
          {response && (
            <div className="content-wrap">
              {response.text}

              {!user && (
              <div>
               <button onClick={handleSignUpIntent}>
                  {selectedMessage}
               </button>
             </div>
           )}
           </div>
         )}
    </>
  );
}

export default UnenagudeSeletaja;
