import { useAuthContext } from './useAuthContext';
import { useState } from 'react';
import axios from 'axios';
export const useLogout = () => {
    const { dispatch } = useAuthContext();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);

    const logout = async () => {
        setLoading(true);
        setError(null);

        try {
            await axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/logout', {},{
              headers: {
                'Content-Type': 'application/json',
              },
              withCredentials: true
            });
  
            setLoading(false);
            localStorage.removeItem('user');
            dispatch({ type: 'LOGOUT' });
    
      
          } catch (error) {
              setLoading(false);
              setError(error.response.data.error);
          }
    };

    return {logout, error, loading};
}