import React from 'react';
import { Helmet } from 'react-helmet';

function Kontakt() {
  return (
    <>
      <Helmet>
        <title>Kontakt - Unenägude Seletaja</title>
        <meta name="description" content="Võtke meiega ühendust, et saada vastuseid oma küsimustele ja abi unenäoseletustega seoses." />
        <meta name="keywords" content="kontakt, unenäoseletaja kontakt, võta meiega ühendust" />
        <meta name="author" content="Unenägude seletaja" />
        <link rel="canonical" href="https://www.unes.ee/kontakt" />
      </Helmet>
      <main style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
        <h1>Kontakt</h1>
        <article>
          <section>
            <p>Meie kontaktandmed:</p>
            <ul>
              <li><strong>E-post:</strong> info[ät].unes.ee</li>
            </ul>
          </section>
        </article>
      </main>
    </>
  );
}

export default Kontakt;
