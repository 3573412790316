// src/components/Header.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import { useAuthContext } from '../hooks/useAuthContext';
import ReactGA from 'react-ga4';


function Header() {
  const { user } = useAuthContext();

  const handleLinkClick = (eventLabel) => {
    ReactGA.event({
      category: 'User',
      action: 'Header Click',
      label: eventLabel
    });
    }


  return (
    <div className="header">
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/" className="navbar-brand" onClick={() => handleLinkClick('Home')}>
          <img src="/img/moon.png" alt="" className="logo-image" /> 
          Unes.ee
        </Link>
      </div>
      <div className="navbar-right">
        {user ? (
          <Link to="/profile" className="navbar-user" onClick={() => handleLinkClick('Profile')}>
            {user.nickname}
          </Link>
        ) : (
          <Link to="/login" className="navbar-login" onClick={() => handleLinkClick('Login')}>
            Logi sisse
          </Link>
        )}
      </div>
    </nav>
    </div>
  );
}

export default Header;