import React from 'react';
import { Helmet } from 'react-helmet';

function KupsistePoliitika() {
  return (
    <>
      <Helmet>
        <title>Küpsisepoliitika - Unenägude Seletaja</title>
        <meta name="description" content="Lugege meie küpsisepoliitika kohta, et saada teada, kuidas me kasutame küpsiseid ja sarnaseid tehnoloogiaid teie kogemuse parandamiseks." />
        <meta name="keywords" content="küpsisepoliitika, küpsised, andmekaitse, privaatsus" />
        <meta name="author" content="Unenägude seletaja" />
        <link rel="canonical" href="https://www.unes.ee/kupsiste-poliitika" />
      </Helmet>
      <main style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
        <h1>Küpsisepoliitika</h1>
        <article>
          <section>
            <p>Käesolev küpsisepoliitika selgitab, kuidas meie veebisait kasutab küpsiseid ja sarnaseid tehnoloogiaid teie kogemuse parandamiseks.</p>
          </section>
          <section>
            <h2>Mis on küpsised?</h2>
            <p>Küpsised on väikesed tekstifailid, mis salvestatakse teie seadmesse, kui külastate veebisaiti. Need aitavad meil veebisaiti paremaks muuta ja pakkuda teile isikupärastatud kogemust.</p>
          </section>
          <section>
            <h2>Küpsiste tüübid</h2>
            <ul>
              <li><strong>Seansiküpsised:</strong> Need on ajutised küpsised, mis kustutatakse pärast brauseri sulgemist.</li>
              <li><strong>Püsiküpsised:</strong> Need küpsised jäävad teie seadmesse kindlaks ajaks või kuni te need kustutate.</li>
              <li><strong>Kolmanda osapoole küpsised:</strong> Need küpsised pärinevad teistelt veebisaitidelt, näiteks reklaamijatelt või analüütikateenuste pakkujatelt.</li>
            </ul>
          </section>
          <section>
            <h2>Kuidas me küpsiseid kasutame?</h2>
            <ul>
              <li>Veebisaidi toimimise tagamiseks</li>
              <li>Kasutajakogemuse parandamiseks</li>
              <li>Analüütiliste andmete kogumiseks</li>
              <li>Reklaamide isikupärastamiseks</li>
            </ul>
          </section>
          <section>
            <h2>Teie valikud küpsiste osas</h2>
            <p>Teil on õigus otsustada, kas soovite küpsiseid aktsepteerida või mitte. Saate oma brauseri seadeid muuta, et küpsiseid keelata või teid nende vastuvõtmisel teavitada. Pidage siiski meeles, et küpsiste keelamine võib mõjutada veebisaidi funktsionaalsust.</p>
          </section>
          <section>
            <h2>Küpsiste poliitika uuendused</h2>
            <p>Me võime seda küpsiste poliitikat aeg-ajalt muuta. Kõik muudatused avaldatakse sellel lehel. Soovitame teil seda lehte regulaarselt külastada, et olla teadlik meie küpsiste poliitika uuendustest.</p>
          </section>
          <section>
            <h2>Kontakt</h2>
            <p>Küsimuste korral küpsiste poliitika kohta võtke meiega ühendust:</p>
            <ul>
              <li><strong>E-post:</strong> info[ät].unes.ee</li>
            </ul>
          </section>
        </article>
      </main>
    </>
  );
}

export default KupsistePoliitika;
